const repo = [
  {
    name: "curlm.in",
    lang: "JavaScript",
    visible: "Public",
    color: "orange",
    define:
      "The ultra-short domain by Curlmin for lightning-fast URL shortening",
    url: "https://github.com/mohdzain98/curlm.in",
  },
  {
    name: "Automated Keyword Extraction",
    lang: "Python",
    visible: "Public",
    color: "blue",
    define: "Applying NLP on collection of articles to extract keywords.",
    url: "https://github.com/mohdzain98/Automated_Keyword_Extraction",
  },
  {
    name: "Alarm_clock",
    lang: "JavaScript",
    visible: "Public",
    color: "orange",
    define: "Simple Alarm clock using JS",
    url: "https://github.com/mohdzain98/Alarm_Clock",
  },
  {
    name: "Text Utils",
    lang: "JavaScript",
    visible: "Public",
    color: "orange",
    define: "A Text utility made with React",
    url: "https://github.com/mohdzain98/textutils",
  },
  {
    name: "pennrangers",
    lang: "HTML",
    visible: "Public",
    color: "red",
    define: "Clash of Clans webpage to display clans stats",
    url: "https://github.com/mohdzain98/pennrangers",
  },
  {
    name: "cocwl",
    lang: "PHP",
    visible: "Public",
    color: "violet",
    define: "Clash of clans Clan Wars Database Management System",
    url: "https://github.com/mohdzain98/cocwl",
  },
  {
    name: "chatApplication",
    lang: "Java",
    visible: "Public",
    color: "#2be811",
    define: "Chat Appliaction using Socket Programming",
    url: "https://github.com/mohdzain98/ChatApplication",
  },
  {
    name: "Color_Images_Encryption",
    lang: "Python",
    visible: "Public",
    color: "blue",
    define: "DNA Based color images encryption",
    url: "https://github.com/mohdzain98/Design_of_DNA_based_Color_Images_Cryptosystem",
  },
  {
    name: "Fees Management System",
    lang: "Python",
    visible: "Public",
    color: "blue",
    define:
      "Python flask based application for running school fees management system",
    url: "https://github.com/mohdzain98/School-Fees-Management-System",
  },
  {
    name: "JavaPython-Integration",
    lang: "Java",
    visible: "Public",
    color: "#2be811",
    define: "Running code written in python in java using Python Interpreter",
    url: "https://github.com/mohdzain98/JavaPython-Integration",
  },
];

export default repo;
